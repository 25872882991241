/**
 * @param {string} sKey - The name of cookie that what you want
 * @return {array} Array of cookies item
 *
 * This function is used for get list item from cookie string
 */
export default function getCookie(sKey) {
  const result = [];
  if (!sKey || !document.cookie) return result;
  const name = `${sKey}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trimStart();
    if (c.indexOf(name) === 0) {
      result.push(c.substring(name.length, c.length));
    }
  }
  return result;
}
