import React, { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from '@assets/logo-growin.svg';
import { UserContext } from '@context/user';
import { checkIsActive } from '../../_metronic/_helpers';

export default function Sidebar() {
  const location = useLocation();
  const userContext = useContext(UserContext);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open ` : '';
  };

  return (
    <>
      <li className="menu-item menu-logo" aria-haspopup="true">
        <NavLink className="menu-link menu-toggle" to="/dashboard">
          <img src={Logo} alt="Logo" height="30" />
        </NavLink>
      </li>

      {userContext.hasAccess('dashboard.read') && (
        <li className={`menu-item ${getMenuItemActive('/dashboard', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/dashboard">
            <span className="fas fa-home svg-icon menu-icon" />
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
      )}

      {userContext.hasAccess('product.read') && (
        <li className={`menu-item ${getMenuItemActive('/product-management', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/product-management">
            <span className="fas fa-box-open svg-icon menu-icon" />
            <span className="menu-text">Product Management</span>
          </NavLink>
        </li>
      )}

      {userContext.hasAccess('order.read') && (
        <li className={`menu-item ${getMenuItemActive('/order-management', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/order-management">
            <span className="fas fa-shopping-basket svg-icon menu-icon" />
            <span className="menu-text">Order Management</span>
          </NavLink>
        </li>
      )}

      {userContext.hasAccess('article.read') && (
        <li className={`menu-item ${getMenuItemActive('/blog-management', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/blog-management">
            <span className="fas fa-book svg-icon menu-icon" />
            <span className="menu-text">Blog Management</span>
          </NavLink>
        </li>
      )}

      {userContext.hasAccess('category.read') && (
        <li className={`menu-item ${getMenuItemActive('/category-management', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/category-management">
            <span className="fas fa-list svg-icon menu-icon" />
            <span className="menu-text">Category Management</span>
          </NavLink>
        </li>
      )}

      <li className="menu-section">
        <b className="menu-text">User Management</b>
      </li>

      {userContext.hasAccess('employee.read') && (
        <li className={`menu-item ${getMenuItemActive('/admin-management', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/admin-management">
            <span className="fas fa-user svg-icon menu-icon" />
            <span className="menu-text">Admin Management</span>
          </NavLink>
        </li>
      )}

      {userContext.hasAccess('user.read') && (
        <li className={`menu-item ${getMenuItemActive('/client-management', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/client-management">
            <span className="fas fa-user svg-icon menu-icon" />
            <span className="menu-text">Client Management</span>
          </NavLink>
        </li>
      )}

      {/* {userContext.hasAccess('vendor.read') && (
        <li className={`menu-item ${getMenuItemActive('/vendor-management', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/vendor-management">
            <span className="fas fa-user svg-icon menu-icon" />
            <span className="menu-text">Vendor Management</span>
          </NavLink>
        </li>
      )}

      {userContext.hasAccess('creator.read') && (
        <li className={`menu-item ${getMenuItemActive('/kreator-management', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/kreator-management">
            <span className="fas fa-user svg-icon menu-icon" />
            <span className="menu-text">Kreator Management</span>
          </NavLink>
        </li>
      )} */}

      {userContext.hasAccess('employeeRole.read') && (
        <li className={`menu-item ${getMenuItemActive('/admin-role-management', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/admin-role-management">
            <span className="fas fa-user svg-icon menu-icon" />
            <span className="menu-text">Admin Role Management</span>
          </NavLink>
        </li>
      )}

      <li className="menu-section">
        <b className="menu-text">Master Data</b>
      </li>

      {userContext.hasAccess('design.read') && (
        <li className={`menu-item ${getMenuItemActive('/design-template', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/design-template">
            <span className="fas fa-tag svg-icon menu-icon" />
            <span className="menu-text">Design Management</span>
          </NavLink>
        </li>
      )}

      <li className="menu-section d-lg-none">
        <b className="menu-text">Account</b>
      </li>
      <li className="menu-item d-lg-none" aria-haspopup="true">
        <NavLink onClick={() => userContext.handleLogOut()} className="menu-link" to="/">
          <span className="fas fa-sign-out-alt svg-icon menu-icon" />
          <span className="menu-text">Log Out</span>
        </NavLink>
      </li>
    </>
  );
}
