import React from 'react';
import loadable from '@loadable/component';
import Loading from '@components/Loading';

export const ProductCreate = loadable(
  () => import(/* webpackChunkName: "product-create" */ './ProductManagementCreate'),
  {
    fallback: <Loading />,
  },
);

export const ProductEdit = loadable(() => import(/* webpackChunkName: "product-edit" */ './ProductManagementEdit'), {
  fallback: <Loading />,
});

export const ProductList = loadable(() => import(/* webpackChunkName: "product-list" */ './ProductManagementList'), {
  fallback: <Loading />,
});
