import React from 'react';
import { node } from 'prop-types';
import { LocaleProvider } from '@context/locale';
import { UserProvider } from '@context/user';
import { BreadcrumbsProvider } from '@context/breadcrumbs';
import { NotificationProvider } from '@context/notification';

function ContextProvider({ children }) {
  return (
    <NotificationProvider>
      <UserProvider>
        <BreadcrumbsProvider>
          <LocaleProvider>{children}</LocaleProvider>
        </BreadcrumbsProvider>
      </UserProvider>
    </NotificationProvider>
  );
}

ContextProvider.propTypes = {
  children: node.isRequired,
};

export default ContextProvider;
