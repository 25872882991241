import React, { useReducer } from 'react';
import { node } from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
const NotificationContext = React.createContext();

const NotificationProvider = ({ children }) => {
  const initialNotificationState = {
    open: false,
    type: '',
    message: 'Default Message',
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SUCCESS':
        return { open: true, type: action.type.toLowerCase(), message: action.message };
      case 'ERROR':
        return { open: true, type: action.type.toLowerCase(), message: action.message };
      case 'WARNING':
        return { open: true, type: action.type.toLowerCase(), message: action.message };
      case 'CLOSE':
        return { ...state, open: false };
      default:
        return state;
    }
  };

  const [notificationState, dispatch] = useReducer(reducer, initialNotificationState);

  return (
    <NotificationContext.Provider value={{ notificationState, dispatch }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={notificationState.open}
        autoHideDuration={6000}
        onClose={() => {
          dispatch({ type: 'CLOSE' });
        }}
      >
        <Alert severity={notificationState.type}>{notificationState.message}</Alert>
      </Snackbar>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: node.isRequired,
};

export { NotificationProvider, NotificationContext };
