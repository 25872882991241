import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorBoundary from '@components/ErrorBoundary';
import ContextProvider from '@context';
import Routes from '@route-gateway';
import { object } from 'prop-types';
import ErrorsPage from '@metronic/modules/ErrorsExamples/ErrorsPage';
import { MaterialThemeProvider } from '@metronic/layout';
import DayJSUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import './styles/index.scss';

function App({ client }) {
  return (
    <ErrorBoundary render={() => <ErrorsPage />}>
      <MuiPickersUtilsProvider utils={DayJSUtils}>
        <ApolloProvider client={client}>
          <MaterialThemeProvider>
            <ContextProvider>
              <Router>
                <Routes />
              </Router>
            </ContextProvider>
          </MaterialThemeProvider>
        </ApolloProvider>
      </MuiPickersUtilsProvider>
    </ErrorBoundary>
  );
}

App.propTypes = {
  client: object.isRequired,
};

export default App;
