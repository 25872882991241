/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from 'react';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';
// import { AsideSearch } from './AsideSearch';
import { AsideMenu } from './aside-menu/AsideMenu';

export function Aside() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses('aside', true),
      asideSecondaryDisplay: objectPath.get(uiService.config, 'aside.secondary.display'),
      asideSelfMinimizeToggle: objectPath.get(uiService.config, 'aside.self.minimize.toggle'),
      extrasSearchDisplay: objectPath.get(uiService.config, 'extras.search.display'),
      extrasNotificationsDisplay: objectPath.get(uiService.config, 'extras.notifications.display'),
      extrasQuickActionsDisplay: objectPath.get(uiService.config, 'extras.quick-actions.display'),
      extrasQuickPanelDisplay: objectPath.get(uiService.config, 'extras.quick-panel.display'),
      extrasLanguagesDisplay: objectPath.get(uiService.config, 'extras.languages.display'),
      extrasUserDisplay: objectPath.get(uiService.config, 'extras.user.display'),
    };
  }, [uiService]);

  const tabs = {
    tabId1: 'kt_aside_tab_1',
    tabId2: 'kt_aside_tab_2',
  };
  const [activeTab] = useState(tabs.tabId2);

  return (
    <>
      {/* begin::Aside */}
      <div id="kt_aside" className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}>
        {layoutProps.asideSecondaryDisplay && (
          <>
            {/* begin::Secondary */}
            <div className="aside-secondary d-flex flex-row-fluid">
              {/* begin::Workspace */}
              <div className="aside-workspace scroll scroll-push">
                <div className="tab-content">
                  {/* <AsideSearch isActive={activeTab === tabs.tabId1} /> */}
                  <AsideMenu isActive={activeTab === tabs.tabId2} />
                </div>
              </div>
              {/* end::Workspace */}
            </div>
            {/* end::Secondary */}
          </>
        )}
      </div>
      {/* end::Aside */}
    </>
  );
}
