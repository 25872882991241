import React from 'react';
import loadable from '@loadable/component';
import Loading from '@components/Loading';

export const ClientCreate = loadable(() => import(/* webpackChunkName: "client-create" */ './ClientManagementCreate'), {
  fallback: <Loading />,
});

export const ClientEdit = loadable(() => import(/* webpackChunkName: "client-edit" */ './ClientManagementEdit'), {
  fallback: <Loading />,
});

export const ClientList = loadable(() => import(/* webpackChunkName: "client-list" */ './ClientManagementList'), {
  fallback: <Loading />,
});
