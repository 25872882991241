import React from 'react';
import loadable from '@loadable/component';
import Loading from '@components/Loading';

export const BlogCreate = loadable(() => import(/* webpackChunkName: "blog-create" */ './BlogCreate'), {
  fallback: <Loading />,
});

export const BlogEdit = loadable(() => import(/* webpackChunkName: "blog-edit" */ './BlogEdit'), {
  fallback: <Loading />,
});

export const BlogList = loadable(() => import(/* webpackChunkName: "blog-list" */ './BlogList'), {
  fallback: <Loading />,
});
