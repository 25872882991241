import React from 'react';
import loadable from '@loadable/component';
import Loading from '@components/Loading';

export const CategoryList = loadable(
  () => import(/* webpackChunkName: "product-create" */ './KategoriManagementList'),
  {
    fallback: <Loading />,
  },
);

export const CategoryCreate = loadable(
  () => import(/* webpackChunkName: "product-create" */ './KategoriManagementCreate'),
  {
    fallback: <Loading />,
  },
);

export const CategoryUpdate = loadable(
  () => import(/* webpackChunkName: "product-create" */ './KategoriManagementEdit'),
  {
    fallback: <Loading />,
  },
);
