import { ApolloClient, InMemoryCache } from '@apollo/client';
import createLink from './link';

export default function createApolloClient({ options = {}, initialState, shouldUseBatch } = {}) {
  const cache = new InMemoryCache();

  const client = new ApolloClient({
    connectToDevTools: __DEV__,
    queryDeduplication: true,
    link: createLink({ shouldUseBatch }),
    cache: initialState ? cache.restore(initialState) : cache,
    ...options,
  });
  return client;
}
