import React from 'react';

import Spinner from 'react-bootstrap/Spinner';
import style from './index.module.scss';
export default function Loading() {
  return (
    <div className={style.absoluteCenter}>
      <Spinner animation="border" variant="primary" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
}
