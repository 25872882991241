import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from '@context/user';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Breadcrumbs from '@components/Breadcrumbs';
import style from './index.module.scss';

export default function Navbar(props) {
  const userContext = useContext(UserContext);

  return (
    <>
      <div className={style.headerCustom + ` ${props.isRelative ? style.relative : ''} ` + style.navBg}>
        <div>
          <Breadcrumbs />
        </div>
        <div>
          {userContext.userState.isAuthorized ? (
            <div className={style.loggedInUser}>
              <div className="mr-3 text-placeholder">
                Hi,<b className={style.hiPerson}> {userContext.userState.data?.name || ''}&nbsp;</b>
              </div>
              <PersonIcon className={`${style.personIcon} mr-4`} />
              <span
                onClick={() => {
                  userContext.handleLogOut();
                }}
              >
                <ExitToAppIcon className={style.exitApp} />
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
}

Navbar.propTypes = {
  isRelative: PropTypes.bool,
};

Navbar.defaultProps = {
  isRelative: false,
};
