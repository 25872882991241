import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { MetronicLayoutProvider, MetronicSplashScreenProvider, MetronicSubheaderProvider } from './_metronic/layout';
import createApolloClient from './apollo/client';
import 'socicon/css/socicon.css';
// import 'react-datepicker/dist/react-datepicker.css';
import '@styles/index.scss';
import '@metronic/_assets/plugins/keenthemes-icons/font/ki.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const rootElement = document.getElementById('root');

const client = createApolloClient({ shouldUseBatch: true });

render(
  <MetronicLayoutProvider>
    <MetronicSubheaderProvider>
      <MetronicSplashScreenProvider>
        <App client={client} />
      </MetronicSplashScreenProvider>
    </MetronicSubheaderProvider>
  </MetronicLayoutProvider>,
  rootElement,
);
