import React from 'react';
import loadable from '@loadable/component';
import Loading from '@components/Loading';

export const Login = loadable(() => import(/* webpackChunkName: "login" */ './Login'), {
  fallback: <Loading />,
});

export const Forgot = loadable(() => import(/* webpackChunkName: "forgot" */ './Forgot'), {
  fallback: <Loading />,
});

export const Unauthorized = loadable(() => import(/* webpackChunkName: "unauthorized" */ './Unauthorized'), {
  fallback: <Loading />,
});
