import React, { useState } from 'react';
import { node } from 'prop-types';

const BreadcrumbsContext = React.createContext({
  items: [],
  setItems: () => {},
});

const BreadcrumbsProvider = ({ children }) => {
  const [items, setItems] = useState([]);

  return <BreadcrumbsContext.Provider value={{ items, setItems }}>{children}</BreadcrumbsContext.Provider>;
};
BreadcrumbsProvider.propTypes = {
  children: node.isRequired,
};

export { BreadcrumbsProvider, BreadcrumbsContext };
