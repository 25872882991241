import React, { useMemo, useContext } from 'react';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import PersonIcon from '@material-ui/icons/Person';
import { UserContext } from '@context/user';
import Logo from '@assets/logo-growin.svg';
import style from './index.module.scss';
// import { toAbsoluteUrl } from '../../../_helpers';
import { useHtmlClassService } from '../../_core/MetronicLayout';

export function HeaderMobile() {
  const uiService = useHtmlClassService();
  const userContext = useContext(UserContext);
  const layoutProps = useMemo(() => {
    return {
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      headerMobileCssClasses: uiService.getClasses('header_mobile', true),
      headerMobileAttributes: uiService.getAttributes('header_mobile'),
    };
  }, [uiService]);

  return (
    <>
      {/*begin::Header Mobile*/}
      <div
        id="kt_header_mobile"
        className={`header-mobile ${layoutProps.headerMobileCssClasses}`}
        {...layoutProps.headerMobileAttributes}
      >
        {/* begin::Logo */}
        <Link to="/">
          <img alt="Logo" className="logo-default max-h-30px" src={Logo} />
        </Link>
        {/* end::Logo */}

        {/* begin::Toolbar */}
        <div className="d-flex align-items-center">
          {layoutProps.asideDisplay && (
            <>
              <div className={style.loggedInUser}>
                <div className="mr-3 text-placeholder">
                  Hi,<b className={style.hiPerson}> {userContext.userState.data?.name || ''}&nbsp;</b>
                </div>
                <PersonIcon className={`${style.personIcon} mr-4`} />
              </div>
              {/* <button
                type="button"
                style={{ height: '32px', width: '32px', padding: '0' }}
                className="btn"
                id="kt_header_mobile_topbar_toggle"
              >
                <PersonIcon style={{ height: '100%', width: '100%' }} className="btn p-0 m-0" />
              </button> */}

              <button
                type="button"
                className="btn p-0 ml-5 mr-2 burger-icon burger-icon-left"
                id="kt_aside_mobile_toggle"
              >
                <span />
              </button>
            </>
          )}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header Mobile */}
    </>
  );
}
