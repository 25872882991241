import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { node } from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import Loading from '@components/Loading';
import { get } from 'lodash';
import refreshTokenMutation from './mutations/refreshToken.graphql';
import employeeMeQuery from './queries/employeeMe.graphql';

const UserContext = React.createContext({
  handleLogOut: () => {},
  hasAccess: () => {},
  userState: { isAuthorized: false, data: {} },
});

const UserProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['SID_GROWIN_CMS']);

  const handleLogOut = () => {
    removeCookie('SID_GROWIN_CMS', { path: '/' });

    window.location.assign('/auth/login');
  };

  const { data: employeeData, error: employeeError, loading: employeeLoading } = useQuery(employeeMeQuery);

  const [handleMeAdmin] = useMutation(refreshTokenMutation);

  useEffect(() => {
    // Renew token when date is past one day before expiry date
    if (cookies.SID_GROWIN_CMS) {
      const expire = cookies.SID_GROWIN_CMS && new Date(parseInt(cookies.SID_GROWIN_CMS.expToken));
      const oneDayBeforeTokenExpire = new Date(expire);
      oneDayBeforeTokenExpire.setDate(expire.getDate() - 1);

      if (new Date() > oneDayBeforeTokenExpire) {
        handleMeAdmin({
          variables: {
            token: cookies?.SID_GROWIN_CMS?.refreshToken || '',
          },
        }).then(({ data }) => {
          const { refreshToken, token, expToken } = data.employeeAccessTokenRefresh || {};
          setCookie(
            'SID_GROWIN_CMS',
            { refreshToken, token, expToken },
            { path: '/', expires: new Date(parseInt(expToken)) },
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasAccess = path => {
    // superuser has full authority regardless of permissions
    if (employeeData.employeeMe.superuser) return true;
    return get(employeeData?.employeeMe.employeeRole.permissions || {}, path, false);
  };

  if (employeeLoading) {
    return <Loading />;
  }

  return (
    <UserContext.Provider
      value={{
        userState: { isAuthorized: !employeeError, data: employeeData?.employeeMe || {} },
        handleLogOut,
        hasAccess,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
UserProvider.propTypes = {
  children: node.isRequired,
};

export { UserProvider, UserContext };
