/* eslint-disable react/prop-types */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import Sidebar from '@/components/Sidebar';

export function AsideMenuList({ layoutProps }) {
  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <Sidebar />
      </ul>
    </>
  );
}
