import React from 'react';
import loadable from '@loadable/component';
import Loading from '@components/Loading';

export const TemplateCreate = loadable(() => import(/* webpackChunkName: "template-create" */ './TemplateCreate'), {
  fallback: <Loading />,
});

export const TemplateEdit = loadable(() => import(/* webpackChunkName: "template-edit" */ './TemplateEdit'), {
  fallback: <Loading />,
});

export const TemplateList = loadable(() => import(/* webpackChunkName: "template-list" */ './TemplateList'), {
  fallback: <Loading />,
});
