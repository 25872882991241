import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbsContext } from '@context/breadcrumbs';

export default function Breadrumbs() {
  const breadcrumbsContext = useContext(BreadcrumbsContext);

  const items = breadcrumbsContext.items;
  if (!items || !items.length) {
    return '';
  }

  return (
    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0">
      <Link className="breadcrumb-item" to={items.slice(-1).pop().pathname}>
        {items.slice(-1).pop().title}
      </Link>
      {items.map((item, index) => (
        <li className="breadcrumb-item" key={index}>
          <Link className="text-muted " to={{ pathname: item.pathname }}>
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}
