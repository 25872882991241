import 'unfetch';
import isEmpty from 'lodash/isEmpty';
import { GQL_HOST } from '@constants';
import { ApolloLink } from '@apollo/client';
// import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import cookies from '@helpers/getCookie';

// eslint-disable-next-line no-unused-vars
export default function createLink({ shouldUseBatch }) {
  const retryLink = new RetryLink({
    delay: {
      initial: 1500,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: __DEV__ ? 5 : 10,
      retryIf: (error, { query }) => Boolean(error) && /^query$/i.test(query?.definitions[0]?.operation),
    },
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from cookie if it exists
    const cookie = cookies('SID_GROWIN_CMS');
    const auth_cookie = !isEmpty(cookie) ? JSON.parse(cookie[0]) : '';
    // return the headers to the context so httpLink can read them
    // console.log(auth_cookie.token);
    return {
      headers: {
        ...headers,
        authorization: auth_cookie?.token || '',
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const operationName = (operation && operation.operationName) || '';
    const operationVariables = (operation && operation.variables && JSON.stringify(operation.variables)) || '';

    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path, extensions }) => {
        const extensionsMessage = isEmpty(extensions) ? '' : `, Extensions: ${JSON.stringify(extensions)}`;

        return console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, operationName: ${operationName}, operationVariables: ${operationVariables}${extensionsMessage}`,
        );
      });
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}, operationName: ${operationName}`);
      console.error(`[Network error] stack: ${networkError.stack}`);
    }
  });

  const consoleLink = __DEV__
    ? new ApolloLink((operation, forward) => {
        operation.setContext({ start: new Date() });
        console.groupCollapsed(`Starting request for ${operation.operationName}`);
        console.log('variables', operation.variables);
        console.groupEnd();

        return forward(operation).map(data => {
          const time = new Date() - operation.getContext().start;

          console.groupCollapsed(`End request for ${operation.operationName} in ${time}ms`);
          console.log('data', data);
          console.groupEnd();

          return data;
        });
      })
    : null;

  // const RequestClassLink = shouldUseBatch ? BatchHttpLink : HttpLink;

  const uploadLink = createUploadLink({
    uri: GQL_HOST,
  });

  // const requestLink = new RequestClassLink({
  //   uri: GQL_HOST,
  // });
  const links = [retryLink, errorLink, authLink, uploadLink, consoleLink].filter(Boolean);
  // const links = [retryLink, errorLink, requestLink, uploadLink, consoleLink].filter(Boolean);
  return ApolloLink.from(links);
}
