import React from 'react';
import loadable from '@loadable/component';
import Loading from '@components/Loading';

export const OrderList = loadable(() => import(/* webpackChunkName: "order-list" */ './OrderList'), {
  fallback: <Loading />,
});

export const OrderEdit = loadable(() => import(/* webpackChunkName: "order-edit" */ './OrderEdit'), {
  fallback: <Loading />,
});
