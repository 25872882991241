import React from 'react';
import loadable from '@loadable/component';
import Loading from '@components/Loading';

export const AdminRoleCreate = loadable(() => import(/* webpackChunkName: "admin-role-create" */ './AdminRoleCreate'), {
  fallback: <Loading />,
});

export const AdminRoleEdit = loadable(() => import(/* webpackChunkName: "admin-role-edit" */ './AdminRoleEdit'), {
  fallback: <Loading />,
});

export const AdminRoleList = loadable(() => import(/* webpackChunkName: "amdin-role-list" */ './AdminRoleList'), {
  fallback: <Loading />,
});
